import styled from 'styled-components';
import { path } from 'ramda';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${path(['theme', 'palette', 'nnBlue'])};
  height: 100%;
  justify-content: space-between;
  padding: 25px;
`;

export const Content = styled.div`
  text-align: center;
`;

export const Title = styled.div`
  font-size: 38px;
  font-weight: 500;
  margin-bottom: 50px;
`;

export const Text = styled.div`
  max-width: 600px;
  font-size: 18px;
  margin-bottom: 30px;
`;

export const Promo = styled.div`
  color: ${path(['theme', 'palette', 'graniteGrey'])};
  max-width: 600px;
  font-size: 12px;
  margin-bottom: 30px;
`;

export const LottieWrapper = styled.div`
  max-height: 300px;
`
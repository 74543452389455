import React, { FC } from 'react';
import Lottie from 'react-lottie-player';
import { navigate } from 'gatsby';
import {
  TranslationKey,
  useTranslate,
  useUserContext,
  DEFAULT_STEP,
  DEFAULT_CATEGORY,
  DEFAULT_STEPS,
} from '@nn-virtual-pen/education/data-access';
import {
  ActionButton,
  ActionButtonSize,
  ActionButtonVariant,
} from '@nn-virtual-pen/ui';
import * as Styled from './education-features-congratulations-page.styled';
import congratulations from 'static/animations/congratulations.json';
import { RoutePath } from '@nn-virtual-pen/education/utils';

interface CongratulationsPageProps {
  product: string;
  promoCode: string;
}

export const CongratulationsPage: FC<CongratulationsPageProps> = ({
  product,
  promoCode,
}) => {
  const { t } = useTranslate();
  const { setStepAndCategory, setSteps } = useUserContext();

  const onClick = () => {
    setStepAndCategory(DEFAULT_STEP, DEFAULT_CATEGORY);
    setSteps(DEFAULT_STEPS);
    void navigate(RoutePath.Learning);
  };

  return (
    <Styled.Wrapper>
      <Styled.Content>
        <Styled.Title>{t(TranslationKey.congratulations_title)}</Styled.Title>
        <Styled.Text>
          {t(TranslationKey.congratulations_complete, { product })}
        </Styled.Text>
        <Styled.Text>{t(TranslationKey.congratulations_try_again)}</Styled.Text>
        {promoCode && <Styled.Promo>{t(TranslationKey.promo_code, { promoCode })}</Styled.Promo>}
      </Styled.Content>
      <Styled.LottieWrapper>
        <Lottie animationData={congratulations} play loop={false} />
      </Styled.LottieWrapper>
      <ActionButton
        size={ActionButtonSize.wide}
        variant={ActionButtonVariant.primary}
        onClick={onClick}
      >
        {t(TranslationKey.congratulations_start_over)}
      </ActionButton>
    </Styled.Wrapper>
  );
};

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { CongratulationsPage } from '@nn-virtual-pen/education/features/congratulations-page';
import { Helmet } from 'react-helmet';
import { cond, find, path, pipe, prop, propEq, T } from 'ramda';
import { GenericLayout } from '../layouts';
import { withRequireConfig } from '../hoc';
import { Initialization } from '../components/initialization';

export const Congratulations = ({ user }) => {
  const { configuration } = user;

  const data = useStaticQuery(graphql`
    query promoCode {
      promoCode: allCountryInformationJson {
        nodes {
          Country
          PromoCode {
            Product
            Code
          }
        }
      }
    }
  `);

  const { Code: promoCode } = pipe(
    path(['promoCode', 'nodes']),
    find(propEq('Country', configuration.country)),
    path(['PromoCode']),
    find(propEq('Product', configuration.product)),
)(data) as any;

  return (
    <GenericLayout>
      <Helmet title="Congratulations" />
      <CongratulationsPage product={configuration.product} promoCode={promoCode} />
    </GenericLayout>
  );
};

export default withRequireConfig(
  cond([
    [prop('isLoading'), Initialization],
    [T, Congratulations],
  ])
);
